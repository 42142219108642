import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import showdown from 'showdown';
import LinkButton from '../LinkButton/LinkButton';
import * as styles from './enterior.module.scss';
import { Link } from 'gatsby';

const Enterior = ({ enterior }) => {
  const converter = new showdown.Converter();
  const intl = useIntl();

  const image1 = enterior.image1;
  const image2 = enterior.image2;
  const image3 = enterior.image3;

  return (
    <Container>
      <Row className={styles.enteriorSection}>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 6, order: 1 }}
          className={styles.enteriorText}
        >
          <h3 className="title">{enterior.title}</h3>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(enterior.paragraph)
              }}
            />
          </span>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 2 }}
          className="text-center"
        >
          <GatsbyImage
            image={getImage(image1)}
            alt={enterior.image1Alt}
            className="image"
          />
        </Col>
      </Row>
      <Row className="text-center py-4">
        <Col md={6}>
          <Link to="/enterior/services">
            <GatsbyImage
              image={getImage(image2)}
              alt={enterior.image2Alt}
              className="image"
            />
          </Link>
          <p className={styles.button}>
            <LinkButton
              path="/enterior/services"
              title={intl.formatMessage({ id: 'button.services' })}
            />
          </p>
        </Col>

        <Col md={6} className="text-center">
          <Link to="/enterior/portfolio">
            <GatsbyImage
              image={getImage(image3)}
              alt={enterior.image3Alt}
              className="image"
            />
          </Link>
          <p className={styles.button}>
            <LinkButton
              path="/enterior/portfolio"
              title={intl.formatMessage({ id: 'button.portfolio' })}
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Enterior;
