import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import c from 'classnames';

import * as styles from './header.module.scss';

const Header = ({ children, className }) => {
  return (
    <Container fluid className={c(className, 'px-0')}>
      <Container fluid className={c(className, styles.headerBCG)}>
        <Container>
          <Row>
            <Col className={styles.headerText}>{children}</Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default Header;
