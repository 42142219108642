import React from 'react';
import { Link } from 'gatsby';
import * as styles from './link.button.module.scss';
import { RightArrowIcon } from '../../images/index';

const LinkButton = ({ path, title }) => {
  return (
    <Link to={path} className={styles.link}>
      {title} <RightArrowIcon />
    </Link>
  );
};

export default LinkButton;
