import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import * as styles from './breadcrumbs.module.scss';
import c from 'classnames';

const BreadCrumbs = ({ crumbs, className }) => {
  const intl = useIntl();

  return (
    <Container fluid className={className}>
      <Container>
        <Row>
          <Col className={styles.container}>
            {crumbs.map((crumb, index) =>
              crumb === 'home' ? (
                <span key={index}>
                  <Link to="/">
                    {intl.formatMessage({ id: `main.nav.${crumb}` })}
                  </Link>
                  {' > '}
                </span>
              ) : index !== crumbs.length - 1 ? (
                <span key={index}>
                  <Link
                    to={
                      crumb === 'portfolio'
                        ? '/enterior/' + crumb.replaceAll('\n', '')
                        : '/' + crumb.replaceAll('\n', '')
                    }
                  >
                    {intl.formatMessage({ id: `main.nav.${crumb}` })}
                  </Link>
                  {' > '}
                </span>
              ) : (
                <Link to="#" key={index}>
                  {crumb.replaceAll('\n', '')}
                </Link>
              )
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default BreadCrumbs;
